import {
  Flex,
  Box,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Input
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";

import { Route, BrowserRouter, useLocation, hashHistory } from 'react-router-dom';

import Entry from "./components/Entry";

const COLORS = [
  "#ffc09f",
  "#ffee93",
  "#a0ced9",
  "#adf7b6"
]

const RowOfCells = (props) => {
  return (
    <Tr>
      <Td textAlign="right" style={{ padding: "5px 10px" }}>
        {(props.time % 12) + 1}:00 {props.time >= 11 ? <>PM</> : <>AM</>}
      </Td>

      <Entry
        borderY="1px dashed gray"
        width="100px"
        onMouseEnter={() => {
          props.changeFunc(0);
        }}
        onMouseDown={() => {
          props.setShadingStateValue(!props.rowState[0]);
          props.setShadingState(true);
          props.setAbsoluteVal(0, !props.rowState[0]);
        }}
        onMouseUp={() => {
          props.setShadingState(false);
        }}
        shaded={props.rowState[0] ? 1 : 0}
        color={props.rowColors[0]}
      />

      {[...Array(5)].map((x, i) => (
        <Entry
          border="1px dashed gray"
          key={i}
          onMouseEnter={() => {
            props.changeFunc(i + 1);
          }}
          onMouseDown={() => {
            props.setShadingStateValue(!props.rowState[i + 1]);
            props.setShadingState(true);
            props.setAbsoluteVal(i + 1, !props.rowState[i + 1]);
          }}
          onMouseUp={() => {
            props.setShadingState(false);
          }}
          shaded={props.rowState[i + 1] ? 1 : 0}
          color={props.rowColors[i + 1]}
        />
      ))}

      <Entry
        borderY="1px dashed gray"
        width="100px"
        onMouseEnter={() => {
          props.changeFunc(6);
        }}
        onMouseDown={() => {
          props.setShadingStateValue(!props.rowState[6]);
          props.setShadingState(true);
          props.setAbsoluteVal(6, !props.rowState[6]);
        }}
        onMouseUp={() => {
          props.setShadingState(false);
        }}
        shaded={props.rowState[6] ? 1 : 0}
        color={props.rowColors[6]}
      />
    </Tr>
  );
};

const App = () => {
  const [matrix, setMatrix] = useState(
    Array.from({ length: 17 }, () => Array.from({ length: 7 }, () => false))
  );

  const [colors, setColors] = useState(
    Array.from({ length: 17 }, () => Array.from({ length: 7 }, () => 'aliceblue'))
  );

  const [events, setEvents] = useState([]);

  const [shading, setShading] = useState(false);
  const [shadingValue, setShadingValue] = useState(true);

  const setEventsWrapper = (v) => {
    // console.log()
    // window.history.pushState(null, null, "#"+btoa(JSON.stringify({events:v, matrix:matrix})))
    window.location.hash=btoa(JSON.stringify({events:v, matrix:matrix}))
    // console.log(v)
    setEvents(v)
  }

  const updateEvents = () => {
    let copy = []
    let colorsCopy = [...colors]

    let cont = false

    for(let i = 0; i < 7; ++i){
      for(let j = 0; j < 17; ++j){
        if (cont){
          if (matrix[j][i]) {
            cont = true

            colorsCopy[j][i] = COLORS[copy.length % COLORS.length]

          } else {
            cont = false
            copy[copy.length-1].end = j-1
          }
        } else {
          if (matrix[j][i]){
            // console.log(j, i)
            let prev_entry = events.find(e => e.row == j && e.col == i)

            let entry = {
              color: COLORS[(copy.length+1) % COLORS.length],
              text: "",
              row: j,
              col: i,
              end: -1
            }

            if (prev_entry){
              entry.text = prev_entry.text
            }

            copy.push(entry)

            colorsCopy[j][i] = COLORS[copy.length % COLORS.length]

            cont = true
          } else {
            cont = false;
          }
        }
      }
    }

    // console.log(copy)

    setEventsWrapper(copy)
    // setColors(colorsCopy)
  }

  const updateEntryText = (idx, val) => {
    if (!val){
      return;
    }
    let copy = [...events]
    // console.log(copy)
    copy[idx].text = val
    setEventsWrapper(copy)
  }

  const handleShadeVal = (row, column, val) => {
    let copy = [...matrix];
    copy[row][column] = val;
    setMatrix(copy);

    updateEvents();
  };

  const handleShade = (row, column) => {
    let copy = [...matrix];
    copy[row][column] = shadingValue;
    setMatrix(copy);

    updateEvents();
  };

  const { hash } = useLocation();

  useEffect(() => {
    let savedEvents = [], savedMatrix = {};
    let x = {};
    try{
      x = JSON.parse(atob(hash.slice(1)))

      // console.log("bruh", savedEvents)
    } catch (e) {
      return;
    }

    savedEvents = x.events;
    savedMatrix = x.matrix;

    // console.log(savedEvents)
    setMatrix(savedMatrix)
    setEvents(savedEvents)
  }, [])

  useEffect(() => {
    let copy = []
    let colorsCopy = [...colors]

    let cont = false

    for(let i = 0; i < 7; ++i){
      for(let j = 0; j < 17; ++j){
        if (cont){
          if (matrix[j][i]) {
            cont = true

            colorsCopy[j][i] = COLORS[copy.length % COLORS.length]

          } else {
            cont = false
            copy[copy.length-1].end = j-1
          }
        } else {
          if (matrix[j][i]){
            // console.log(j, i)
            let prev_entry = events.find(e => e.row == j && e.col == i)

            let entry = {
              color: COLORS[(copy.length+1) % COLORS.length],
              text: "",
              row: j,
              col: i,
              end: -1
            }

            if (prev_entry){
              entry.text = prev_entry.text
            }

            copy.push(entry)

            colorsCopy[j][i] = COLORS[copy.length % COLORS.length]

            cont = true
          } else {
            cont = false;
          }
        }
      }
    }

    // console.log(copy)

    // setEventsWrapper(copy)
    setColors(colorsCopy)
  }, [matrix])
  


  return (
    <Flex
      justify="center"
      align="center"
      height="100vh"
      flexDirection="row"
      justifyContent="space-evenly"
    >
      <Box>
        <TableContainer>
          <Table
            style={{
              userSelect: "none",
            }}
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Mon</Th>
                <Th>Tues</Th>
                <Th>Wed</Th>
                <Th>Thurs</Th>
                <Th>Fri</Th>
                <Th>Sat</Th>
                <Th>Sun</Th>
              </Tr>
            </Thead>

            <Tbody>
              {[...Array(17)].map((x, i) => (
                <RowOfCells
                  key={i}
                  time={i + 6}
                  changeFunc={
                    shading
                      ? (j) => {
                          handleShade(i, j);
                        }
                      : () => {}
                  }
                  setShadingState={setShading}
                  setShadingStateValue={setShadingValue}
                  setAbsoluteVal={(j, k) => {
                    handleShadeVal(i, j, k);
                  }}
                  rowState={matrix[i]}
                  rowColors={colors[i]}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Heading marginBottom="20px">Events</Heading>
        <Flex gap="15px" flexDirection="column">
          {events.map((k, v) => {console.log(k); return (
            <Flex key={v} flexDirection="row">
              <Box
                style={{
                  backgroundColor: k.color,
                  borderTopLeftRadius: "3px",
                  borderBottomLeftRadius: "3px"
                }}
              >&nbsp;
              </Box>
              <Input 
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                
                onInput={e => updateEntryText(v, e.target.value)}
                value={k.text}
              />
            </Flex>
          )})}
        </Flex>
      </Box>
    </Flex>
  );
};

const Wrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

export default Wrapper;
