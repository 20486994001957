import { Box, Td } from "@chakra-ui/react";
import React, { usestate } from "react";

const Entry = (props) => {
  return (
    <Td
      {...props}
      width="100px"
      style={{
        borderColor: "gray",
        borderTop: "1px dashed gray",
        padding: "20px",
      }}
      backgroundColor={props.shaded ? props.color : "aliceblue"}
    ></Td>
  );
};

export default Entry;
